$(document).ready(function() {
	$(".menu").hoverIntent({
		selector: 'ul li',
		over: function() {
			var t = $(this);
			$('ul.subMenu').removeClass('over');
			t.children('ul').addClass('over');
		},
		out: function() {
			var t = $(this);
			t.children('ul').removeClass('over');
		},
		timeout: 400
	});
});

$(document).ready(function() {
	var status = $('.slideCounter');
	var slickElement = $('.sliderContent');
	var toggle = $('#sliderLeft');
	var credit = $('#credit');
	var slides = $('.sliderContent div');

	slickElement.on('init reInit afterChange', function(event, slick, currentSlide) {
		//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
		var i = (currentSlide ? currentSlide : 0) + 1;
		status.text(i + '/' + slick.slideCount);
	});

	slickElement.on('init reInit afterChange', function(event, slick, currentSlide) {
		var c = $(slides[0]);
		if (typeof currentSlide !== 'undefined') {
			c = $(slides[currentSlide]);
		}
		credit.text( c.attr('title') );
	});

	var opts = {
		slide: 'div',
		pauseOnHover: false,
		autoplay: true,
		autoplaySpeed: 4500,
		//variableWidth: true,
		//centerMode: true,
		appendArrows: $('.sliderArrows'),
		prevArrow: '<span class="slick-prev">&lt;</span>',
		nextArrow: '<span class="slick-next">&gt;</span>',
		speed: 1000,
		fade: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: { fade: true }
			}
		]
	};

	if ( slickElement.parent().hasClass('press')) {
		opts.slide = 'img';
		opts.adaptiveHeight = true;
	}

	slickElement.slick(opts);

	toggle.click(function(e) {
		e.preventDefault();

		var t = $(this);
		var target = t.attr('href');
		var sign = t.children('.sign');

		if (slickElement.hasClass('small')) {
			slickElement.removeClass('small');
			sign.text('+');
			$(target).slideUp();
		} else {
			slickElement.addClass('small');
			sign.text('-');
			$(target).slideDown();
		}
	});
});
